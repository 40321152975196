<template>
  <div class="epidemicRes">
    <div class="epidemicRes-t">
      <div class="img"><img src="./img/chenggong.png" alt="" /></div>
      <div class="txt">您的工单已提交成功</div>
    </div>
    <div class="epidemicRes-c"><img src="./img/successInfo.png" alt="" /></div>
    <div class="epidemicRes-b" @click="toReturn">返回</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import {} from "./api.js";
import { getHashParam } from "@/utils/utils.js";

var moment = require("moment");
export default {
  name: "epidemicRes",
  components: {},
  data() {
    return {};
  },
  filters: {},
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  async mounted() {},
  methods: {
    toReturn() {
      this.$router.replace({
        name: "epidemicPrevent",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.epidemicRes {
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  min-height: 100vh;
  background: #fafafa;
  padding: 0 30px 30px;
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }
  padding: 210px 76px 0;
  .epidemicRes-t {
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      font-size: 36px;
      font-weight: 600;
      color: #3781ff;
      line-height: 50px;
    }
  }
  .epidemicRes-c {
    width: 100%;
    margin: 136px 0 182px;
    img {
      width: 100%;
    }
  }
  .epidemicRes-b {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
